import { ref } from 'vue';
import Counter from './components/Counter/Counter.vue';
import Contactform from './components/Forms/ContactForm/ContactForm.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Autoplay, Thumbs } from 'swiper/modules';

import { ElCollapse, ElCollapseItem, ElDropdown, ElDropdownMenu, ElDropdownItem, ElTabs, ElTabPane } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/collapse/style/css';
import 'element-plus/es/components/collapse-item/style/css';
import 'element-plus/es/components/dropdown/style/css';
import 'element-plus/es/components/dropdown-menu/style/css';
import 'element-plus/es/components/dropdown-item/style/css';
import 'element-plus/es/components/tabs/style/css';
import 'element-plus/es/components/tab-pane/style/css';
export default {
  components: {
    // Lazy load Dialog component
    // Dialog: () => import('./components/ElementUI/Dialog/Dialog.vue'),
    Counter,
    ElCollapse,
    ElCollapseItem,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    Swiper,
    SwiperSlide,
    Contactform,
    ElTabs,
    ElTabPane
  },
  mounted() {
    const links = document.querySelectorAll('a[href^="#"]');
    const homeUrl = this.$store.state.home;
    links.forEach(link => {
      link.addEventListener('click', function (event) {
        event.preventDefault();
        const targetId = this.getAttribute('href');
        const targetElement = document.querySelector(targetId);
        if (targetElement) {
          const targetPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({
            top: targetPosition - 80,
            behavior: 'smooth'
          });
        } else {
          window.location.href = homeUrl + '/#nasza-oferta';
        }
      });
    });
  },
  setup() {
    const width = window.innerWidth;
    const activeName = 0;
    const dialogTableVisible = ref(false);
    const fullVideoPlayer = ref(null);
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = swiper => {
      thumbsSwiper.value = swiper;
    };
    const playVideo = () => {
      if (fullVideoPlayer.value) {
        fullVideoPlayer.value.play();
      }
    };
    const pauseVideo = () => {
      if (fullVideoPlayer.value) {
        fullVideoPlayer.value.pause();
      }
    };

    // Return
    const historyIsEmpty = ref(false);
    const returnClick = value => {
      historyIsEmpty.value = window.history.length === 1;
      if (historyIsEmpty.value) {
        window.location.href = value;
      } else {
        window.history.back();
      }
    };
    return {
      width,
      dialogTableVisible,
      fullVideoPlayer,
      playVideo,
      pauseVideo,
      activeName,
      modules: [Navigation, Autoplay, Thumbs],
      thumbsSwiper,
      setThumbsSwiper,
      returnClick
    };
  }
};