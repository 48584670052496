import MessageAlert from '../../../mixins/MessageAlert';
import Recaptcha from '../../../mixins/Recaptcha/Recaptcha.js';
import Loading from '../../../mixins/Loading';
import FormRules from '../../../mixins/Forms/FormRules';
export default {
  name: 'ContactForm',
  props: {
    nonce: {
      type: String,
      default: null
    },
    classItem: {
      type: String,
      default: ''
    }
  },
  mixins: [Recaptcha, MessageAlert, Loading, FormRules],
  data: () => {
    return {
      form: {
        name: '',
        company: '',
        email: '',
        phone: '',
        province: '',
        message: '',
        agreement: false,
        agreement2: false,
        agreement3: false
      },
      send: false
    };
  },
  computed: {
    agreements() {
      return this.$store.state.form_settings.agreements;
    },
    executionArea() {
      return this.$store.state.form_settings.execution_area;
    },
    thankYouPage() {
      return this.$store.state.thank_you_page;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.Loading();
          await this.Recaptcha().then(async token => {
            const agree = [{
              text: this.agreements.agreement_1.replace(/(<([^>]+)>)/ig, ''),
              accepted: this.form.agreement
            }];
            if (this.agreements.agreement_2) {
              agree.push({
                text: this.agreements.agreement_2.replace(/(<([^>]+)>)/ig, ''),
                accepted: this.form.agreement2
              });
            }
            if (this.agreements.agreement_3) {
              agree.push({
                text: this.agreements.agreement_3.replace(/(<([^>]+)>)/ig, ''),
                accepted: this.form.agreement3
              });
            }
            await this.$axios.post('/wp-json/api/form/contact', {
              name: this.form.name,
              company: this.form.company,
              email: this.form.email,
              phone: this.form.phone,
              province: this.form.province,
              body: this.form.message,
              agreements: agree
            }, {
              headers: {
                Token: token
              }
            }).then(async response => {
              window.location.href = this.thankYouPage;
              this.send = true;
            }).catch(e => {
              this.MessageAlert({
                type: 'error',
                message: this.$t('form.notices.send_error'),
                duration: 0,
                showClose: true
              });
            });
          });
          this.Loading();
        } else {
          return false;
        }
      });
    }
  }
};